import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import {withRouter} from 'react-router-dom';
// react plugin for creating charts
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
//import Icon from "@material-ui/core/Icon";
// @material-ui/icons

// core components
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import Select from "../../components/CustomSelect/CustomSelect";
import {grayColor, lightColors, primaryColor} from "../../assets/jss/material-dashboard-react";
import {validateSession} from "../../services/api";
import {withTranslation} from "react-i18next";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Loading from "../../components/Loading/Loading";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {ROWS_PER_PAGE, ROWS_PER_PAGE_OPTIONS} from "../../variables/apiConstants";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
import {nullController} from "../../utils/helpersFunctions";
import TablePagination from "@material-ui/core/TablePagination";
import Snackbar from "../../components/Snackbar/Snackbar";
import Table from "@material-ui/core/Table";
import tableViewStyle from "../../assets/jss/custom-components/tableViewStyle";
import {getOrdersSummary} from "../../services/ordersService";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import {NOTIFICATION_DURATION} from "../../variables/coreConstants";



class OrdersSummary extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isLoading: false,
            requesting: false,
            openForm: false,
            filteredData: [],
            date: { value: 0, label: props.t('TODAY_TXT') },
            dateOptions: [
                { value: 0, label: props.t('TODAY_TXT') },
                { value: 1, label: props.t('YESTERDAY_TXT') },
                { value: 3, label: props.t('LAST_THREE_DAYS_TXT') },
                { value: 7, label: props.t('LAST_WEEK_TXT') },
                { value: 30, label: props.t('LAST_MONTH_TXT') },
                { value: 90, label: props.t('LAST_THREE_MONTHS_TXT') },
                { value: 180, label: props.t('LAST_SIX_MONTHS_TXT') },
                { value: 365, label: props.t('LAST_YEAR_TXT') },
            ],
            rowsPerPage: ROWS_PER_PAGE,
            page: 0,
            notificationMessage: '',
            notification: false,
            color: 'success',
            icon: NotificationSuccessIcon,
        };
    }

    componentDidMount() {
        this.request();
    }

    showNotification = (msj, color, icon) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });
            }.bind(this),
            NOTIFICATION_DURATION
        );
    };



    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: + event.target.value});
        this.setState({ page: 0 });
    };


    hideNotification = (event, reason) => {
        if (reason === 'clickaway') { return; }
        this.setState({ notification: false });
    };


    changeDateSelect = date => {
        this.setState({ date: date, requesting: true });

        let dataParameters = {
            "intervalDays": date.value,
        };

        this.requestOrdersSummaryData(dataParameters);
    };

    request = () => {
        this.setState({ isLoading: true });
        let dataParameters = { "intervalDays": this.state.date.value };
        this.requestOrdersSummaryData(dataParameters)
    };


    requestOrdersSummaryData = (parameters) => {
        const { t } = this.props;

        this.setState({ requesting: true });
        getOrdersSummary(parameters)
            .then((response) => {
                this.setState({
                    data: response.data,
                    filteredData: response.data,
                    requesting: false,
                    isLoading: false
                })
            })
            .catch((error) => {
                this.setState({ isLoading: false, requesting: false });
                if (error.response) {
                    this.showNotification(error.response.message, "danger", NotificationFailedIcon, null);
                    validateSession(error.response, this.props.history);
                } else {
                    this.showNotification(t('SOMETHING_WENT_WRONG'), "danger", NotificationFailedIcon, null);
                }
            });
    };


    // Method to search data
    searchData = (e) => {
        let newData = this.state.data;
        try {
            newData = newData.filter(function(item){
                return item.storeName.toLowerCase().search(e.target.value.toLowerCase()) !== -1
                    || item.account.toLowerCase().search(e.target.value.toLowerCase()) !== -1
                    || item.city.toLowerCase().search(e.target.value.toLowerCase()) !== -1;
            });
            this.setState({filteredData: newData});

        } catch (e) {
            console.log("Something went wrong.");
        }
    };


    render() {
        const { classes, t } = this.props;
        const { page, rowsPerPage, filteredData, isLoading } = this.state;

        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                        <GridItem xs={6} sm={6} md={6}>
                            <Input
                                onChange={this.searchData}
                                className={classes.search}
                                startAdornment={
                                    <InputAdornment position="end">
                                        <SearchIcon className={classes.objectColor}/>
                                    </InputAdornment>
                                }
                                placeholder={t('SEARCH_TXT')}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={6}>
                            <div className={classes.filterContent}>
                                <Select
                                    value={this.state.date}
                                    onChange={this.changeDateSelect}
                                    placeholder={t('SELECT_TEXT')}
                                    options={this.state.dateOptions}
                                    isDisabled={this.state.requesting}
                                    isLoading={this.state.requesting}
                                    closeMenuOnSelect={true}
                                    maxMenuHeight={250}
                                    theme={theme => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary: primaryColor[0],
                                        },
                                    })}
                                    styles={{
                                        menu: provided => ({ ...provided, zIndex: 5 })
                                    }}
                                />
                            </div>
                        </GridItem>
                    </GridContainer>
                    {isLoading ?
                        <Loading/>
                        :
                        <Card>
                            <CardBody>
                                <h4
                                    style={{marginTop: 0, marginBottom: 15, fontWeight: "400", color: grayColor[2] }}>
                                    {t('STORES_TEXT')}
                                </h4>
                                <div className={classes.tableResponsive}>
                                    <Table className={classes.table}>
                                        <TableHead className={classes.thColor}>
                                            <TableRow style={{backgroundColor: lightColors[0]}}>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    {t('STORE_NAME_TEXT')}
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    {t('ACCOUNT_TEXT')}
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    {t('CITY_TEXT')}
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    {t('TOTAL_ORDERS_TEXT')}
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {filteredData.slice(
                                                page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((val, key) => {
                                                return (
                                                    <TableRow key={key} hover={true}>
                                                        <TableCell className={classes.tableCell}>
                                                            {nullController(val, 'storeName')}
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            {nullController(val, 'account')}
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            {nullController(val, 'city')}
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            {nullController(val, 'totalOrders')}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </div>
                                <TablePagination
                                    rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                                    component="div"
                                    count={this.state.filteredData.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    backIconButtonProps={{
                                        'aria-label': 'previous page',
                                    }}
                                    nextIconButtonProps={{
                                        'aria-label': 'next page',
                                    }}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />
                            </CardBody>
                        </Card>
                    }
                </GridItem>
                <Snackbar
                    place="tc"
                    color={this.state.color}
                    icon={this.state.icon}
                    message={this.state.notificationMessage}
                    open={this.state.notification}
                    closeNotification={this.hideNotification}
                    close
                />
            </GridContainer>
        );
    }
}

OrdersSummary.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withRouter(withStyles(tableViewStyle)(withTranslation()(OrdersSummary)));
