/** Create by Marlon Reyes 26/10/2020 **/

import { primaryColor, grayColor } from "../material-dashboard-react.jsx";
import {dangerColor, successColor} from "../material-dashboard-react";

const tableViewStyle = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    rightPaper:{
        float: "right",
        marginBottom: 0,
    },
    search:{
        width: "250px",
        "&:before": {
            backgroundColor: "transparent !important"
        },
        "&:hover:not($disabled):before,&:before": {
            borderColor: grayColor[4] + " !important",
            borderWidth: "1px !important"
        },
        "&:after": {
            borderColor: primaryColor[0]
        },
    },
    objectColor:{
        color: grayColor[0]
    },
    thColor:{
        color: grayColor[2]
    },
    table: {
        marginBottom: "0",
        width: "100%",
        maxWidth: "100%",
        backgroundColor: "transparent",
        borderSpacing: "0",
        borderCollapse: "collapse",
    },
    tableHeadCell: {
        color: "inherit",
        fontWeight: "bold",
        fontFamily: "sans-serif",
        "&, &$tableCell": {
            fontSize: "1em"
        },
    },
    tableCell: {
        fontFamily: "sans-serif",
        lineHeight: "1.42857143",
        padding: "12px 8px",
        verticalAlign: "middle",
        fontSize: "0.8125rem",
    },
    tableCell1: {
        fontFamily: "sans-serif",
        lineHeight: "1.42857143",
        padding: "4px 8px",
        verticalAlign: "middle",
        fontSize: "0.8125rem",
    },
    tableRow: {
        padding: "1px 1px",
    },
    tableResponsive: {
        width: "100%",
        marginTop: 6,
        overflowX: "auto",
        maxHeight: "450px"
    },
    tableResumeResponsive: {
        width: "100%",
        marginTop: 6,
        overflowX: "auto",
        maxHeight: "250px"
    },
    status: {
        alignItems: "center",
        marginRight: 1,
        width: "auto",
    },
    statusSuccess: {
        alignItems: "center",
        marginRight: 1,
        width: 80,
        color: successColor[2]
    },
    statusDanger: {
        alignItems: "center",
        marginRight: 1,
        width: 80,
        color: dangerColor[2]
    },
    hoverRow:{
        cursor: "pointer",
    },
    card:{
        marginTop: "1px",
    },
    tableActionButton: {
        width: "27px",
        height: "27px",
        padding: "0"
    },
    tableActionButtonIcon: {
        color: primaryColor[0],
        width: "17px",
        height: "17px"
    },
    selectFilter:{
        marginTop: "7px",
    },
    root: {
        width: "100%",
    },
    tableWrapper: {
        maxHeight: 450,
        overflow: "auto",
    },
    exportContainer:{
        float: "right",
        marginRight: "5px",
    },
    disabled: {
        "&:before": {
            backgroundColor: "transparent !important"
        }
    },
    cancelButton:{
        border: "1px solid #A2A2A2"
    },
    buttonFit: {
        paddingLeft: 10,
        paddingRight: 10
    },
    filterContent: {
        float: "right",
        width: 250,
        marginBottom: 20
    },
};


export default tableViewStyle;