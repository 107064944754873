import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { primaryColor } from "../../assets/jss/material-dashboard-react";
import CircularProgress from '@material-ui/core/CircularProgress';

const CustomCircularProgress = withStyles({
    root: {
        color: primaryColor[0],
    },
})(CircularProgress);

const useStyles = makeStyles(({
    root: {
        width: "100px",
        height: "100px",
        margin: "0 auto",
    },
}));

export default function Loading() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <CustomCircularProgress size={40} thickness={4} />
        </div>
    );
}