let orderDetails = {
    id: "",

    setOrderDetail : function (values) {
        try {
            this.id = values.orderId ? values.orderId : "";
        } catch (e) { console.log("Something went wrong") }
    },
    cleanOrderDetails : function () {
        this.id = null
    }
};

export default orderDetails;