/**
 * Created By Marlon Reyes 26/10/2020
 **/


// ##############################
// Text comparison
// ##############################

const RESULT_CREATED = 201;
const RESULT_SUCCESS = 200;
const RESULT_SESSION_EXPIRED = "Access Denied";


// ##############################
// Boolean string
// ##############################

const YES = "Yes";
const NO  = "No";


// ##############################
// Pagination's configuration
// ##############################

const ROWS_PER_PAGE                 = 10;
const ROWS_PER_PAGE_DETAILS         = 5;
const ROWS_PER_PAGE_OPTIONS         = [10, 25, 50];
const ROWS_PER_PAGE_OPTIONS_DETAILS = [5, 10, 25];


// ##############################
// Table actions: menu options
// ##############################

const EDIT          = "EDIT_TEXT";
const DUPLICATE     = "DUPLICATE_TEXT";
const DELETE        = "DELETE_TEXT";
const ACTIVATE      = "ACTIVATE_TEXT";
const DEACTIVATE    = "DEACTIVATE_TEXT";
const UNASSIGN      = "UNASSIGN_TEXT";
const SHOW_QR_CODE  = "VIEW_QR_CODE_TEXT";
const DOWNLOAD_QR   = "DOWNLOAD_QR_CODE";
const SEND_INVITE   = "SEND_INVITE_TEXT";


// ##############################
// Applications types identifiers
// ##############################

const ATTENDANCE = {value: 1, label:"Attendance"};
const MANAGE_ACTIVITIES = {value: 2, label:"Manage Activities"};
const MENU = {value: 3, label:"Menu"};


// ##############################
// Number Constants
// ##############################

const NOTIFICATION_DURATION         = 1500;
const NOTIFICATION_DURATION_LARGE   = 2500;


// ##############################
// Specific Constants
// ##############################

const BASE64_HEADER         = "data:image/jpeg;base64,";
const DATA_TYPE_SUPPORTED   = "data:image/";
const QR_CODE_DEEP_LINK_URL = "https://ginih.com/qr/";


// ##############################
// Applications names Constants
// ##############################

const APP_COMMERCE = "Commerce";
const APP_SETUP = "Setup";
const APP_ACTIVITY = "Activity";


// ##############################
// STATUS CONSTANTS
// ##############################

const STATUS_PENDING                = 3;
const STATUS_PROGRESS               = 14;
const STATUS_COMPLETED              = 15;
const STATUS_CANCELLED              = 7;


module.exports = {
    ROWS_PER_PAGE_OPTIONS,
    ROWS_PER_PAGE_OPTIONS_DETAILS,
    ROWS_PER_PAGE,
    ROWS_PER_PAGE_DETAILS,
    RESULT_CREATED,
    RESULT_SUCCESS,
    RESULT_SESSION_EXPIRED,
    NOTIFICATION_DURATION,
    NOTIFICATION_DURATION_LARGE,
    DELETE,
    UNASSIGN,
    ACTIVATE,
    DEACTIVATE,
    EDIT,
    DUPLICATE,
    DOWNLOAD_QR,
    SHOW_QR_CODE,
    SEND_INVITE,
    YES,
    NO,
    ATTENDANCE,
    MANAGE_ACTIVITIES,
    MENU,
    BASE64_HEADER,
    QR_CODE_DEEP_LINK_URL,
    DATA_TYPE_SUPPORTED,
    APP_ACTIVITY,
    APP_COMMERCE,
    APP_SETUP,
    STATUS_CANCELLED,
    STATUS_PENDING,
    STATUS_COMPLETED,
    STATUS_PROGRESS
};