import axios from "axios";
import {getData, getLanguage, removeSession, hexToBase64} from "../utils/helpersFunctions";
import {KJUR} from "jsrsasign";


export async function getService(url, parameters) {
        return axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'Application/json',
                'user-token': getData("userTk"),
                'accept-language': getLanguage(),
            },
        });
}

export async function postService(url, parameters) {
    return axios.post(url, parameters, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'Application/json',
            'user-token': getData("userTk"),
            'accept-language': getLanguage(),
        },
    });
}

export async function putService(url, parameters) {
    const signature = await getSignature(url, parameters, getData("userTk"));
    return axios.put(url, parameters, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'Application/json',
            'authorization': getData("userTk"),
            'accept-language': getLanguage(),
            'Signature': signature
        },
    });
}

export async function deleteService(url, parameters) {
    const signature = await getSignature(url, parameters, getData("userTk"));
    return axios.delete(url,  {
        headers:{
            'Content-Type': 'application/json',
            'Accept': 'Application/json',
            'authorization': getData("userTk"),
            'accept-language': getLanguage(),
            'Signature': signature

        },
        data: parameters
    });
}


/**
 * Method validate if sessions has expired or not.
 * @param {object} response
 * @param {object} history
 **/
export function validateSession(response, history) {
    if (response.status === 403) {
        removeSession();
        history.push("/session-expired");
    }
}


export async function postPublicService(url, parameters) {
    const signature = await getSignature(url, parameters, null);
    return axios.post(url, parameters, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'Application/json',
                'accept-language': getLanguage(),
                'Signature': signature
            },
        });
}



export async function putPublicService(url, parameters) {
    const signature = await getSignature(url, parameters, null);
    return axios.post(url, parameters, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'Application/json',
            'accept-language': getLanguage(),
            'Signature': signature
        },
    });
}


export async function getPublicService(url, parameters) {
    if (parameters === null || parameters === undefined) {
        const signature = await getSignature(url, null, null);
        return axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'Application/json',
                'accept-language': getLanguage(),
                'Signature': signature
            },
        });
    } else {
        const signature = await getSignature(url, parameters, null);
        return axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'Application/json',
                'accept-language': getLanguage(),
                'Signature': signature
            },
            params: parameters,
        });
    }

}

export async function getSignature (url, body, token){

    const message =  JSON.stringify(body) + '.' + token;
    const privateKey = process.env.REACT_APP_PRIVATE_KEY;

    // RSA signature generation
    let sig = new KJUR.crypto.Signature({"alg": "SHA512withRSA"});
    sig.init(privateKey);
    sig.updateString(message);
    
    let signature = await hexToBase64(sig.sign());


    return signature;
}