import React from "react";
import Select, {NonceProvider} from "react-select";
import {getNonceProvider} from "../../utils/helpersFunctions";


function CustomSelect({ ...props }) {
    const { ...rest} = props;
    return (
        <NonceProvider nonce={getNonceProvider()}>
            <Select{...rest}/>
        </NonceProvider>
    );
}

export default CustomSelect;
