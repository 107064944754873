import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import {withRouter} from "react-router-dom";
import Button from "../../components/CustomButtons/Button";
import {
    IMG_ERROR
} from "../../variables/resourcesConstants";
import {withTranslation} from "react-i18next";


const useStyles = {
    paper: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position:'fixed',
    },
    messageComponent: {
        width: "40%",
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        position:'fixed',

    },
    image:{
        width: '170px',
        height: '170px',
    },
    title:{
        marginTop: '15px'
    },

};


class SessionExpired extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            title: '',
            message: this.props.t('SESSION_EXPIRED_MESSAGE'),
            imgMessage: IMG_ERROR,
            isRequesting: true,
        };
    }

    static goToSignIn (history) {
        history.push("/sign-in");
    }


    render() {
        const { classes, t } = this.props;

        const SignInButton = withRouter(({history}) => (
            <Button
                variant="contained"
                color="primary"
                onClick={() => SessionExpired.goToSignIn(history)}
            >
                {t('BTN_SIGN_IN')}
            </Button>
        ));

        return(
            <GridContainer>
                <GridItem xs={12} sm={12} md={12} >
                    <div className={classes.paper}>
                        <div className={classes.messageComponent}>
                            <img className={classes.image} src={this.state.imgMessage}  alt={"Message Icon"}/>
                            <h3>{this.state.message}</h3>
                            <SignInButton/>
                        </div>
                    </div>
                </GridItem>
            </GridContainer>

        );
    }
}

export default withStyles(useStyles)(withTranslation()(SessionExpired));