/**
 * Created By Marlon 10/10/2019
 **/

import {APP_ACTIVITY, APP_COMMERCE, NO, RESULT_CREATED, RESULT_SUCCESS, YES} from "../variables/coreConstants";


export function validateEmail(email) {
    let reg = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i ;
    return reg.test(email);
}


/**
 * @param {date} value (milliseconds)
 * return: Date with format dd-mm-yyyy hh:mm:ss
 **/
export const convertDate = (value) => {
    return (new Date(value).toLocaleString());
};


/**
 * @param {date} _date (format default)
 * return: Time with format hh:mm:ss
 **/
export const formatTime = (_date) => {
    let date = new Date(_date);

    return (date.getHours() < 10 ? ("0" + date.getHours()) : date.getHours()) +
        ":" + (date.getMinutes() < 10 ? ("0" + date.getMinutes()) : date.getMinutes()) + ":" +
        (date.getSeconds() < 10 ? ("0" + date.getSeconds()) : date.getSeconds());
};


/**
 * @param {date} _date (format default)
 * return: Time with format hh:mm:ss
 **/
export const formatShortTime = (_date) => {
    let date = new Date(_date);

    return (date.getHours() < 10 ? ("0" + date.getHours()) : date.getHours()) +
        ":" + (date.getMinutes() < 10 ? ("0" + date.getMinutes()) : date.getMinutes());
};


/**
 * @param {String} stringDate (String time format HH:mm:ss)
 * return: Date
 **/
export const convertStringToDate = (stringDate) => {
    let [hours, minutes, seconds] = stringDate.split(':');

    let date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(seconds);

    return date;
};



/**
 * @param {string} value
 * @param {string} type (Example %, $)
 * return: value with format
 **/
export const formatValue = (value, type) => {
    return value + "" + type ;
};


/**
 * @param {String} code (country code)
 * @param {String} phoneNumber
 * return: Phone number with country code
 **/
export const formatPhone = (code, phoneNumber) => {
    return "+" + code + " " + phoneNumber;
};




/**
 * @param {date} _date (format default)
 * return: Time with format yyyy-mm-dd hh:mm:ss
 **/
export const formatDate = (_date) => {
    let date = new Date(_date),
        month = '' + (date.getMonth() + 1),
        day = '' + date.getDate(),
        year = date.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-') + " " + formatTime(_date);
};


/**
 * @param {String} _day (format three words)
 * return: Day name
 **/
export const formatDay = (_day) => {
    let day;

    switch (_day) {
        case "SUN":
            day = "Sunday";
            break;
        case "MON":
            day = "Monday";
            break;
        case "TUE":
            day = "Tuesday";
            break;
        case "WED":
            day = "Wednesday";
            break;
        case "THU":
            day = "Thursday";
            break;
        case "FRI":
            day = "Friday";
            break;
        case "SAT":
            day = "Saturday";
            break;
        default:
            day = "Invalid";
            break;
    }

    return day;
};


/**
 * Method that assigns the current date
 * return string date in format yyyy-mm-dd
 **/
export const getTodayDate = () => {
    let d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
};


/**
 * @param {array} _selectData (structure [{value: value, label: label}])
 * return: true or false
 **/
export const selectValidation = (_selectData) => {
    return _selectData === undefined || _selectData === null || _selectData.value === 0 ||
        _selectData === "" ||  _selectData.length === 0 || _selectData === [];
};


/**
 * Method that validate if the fields are null or empty
 * @param {array} fields
 * return: true or false
 **/
export const validateFields = (fields) => {
    let result = 0;
    for(let i = 0; i < fields.length; i++)
    {
        if(fields[i] === "" || fields[i] === null || fields[i] === undefined)
            result += 1;
    }

    return result < 1;
};


/**
 * Method check if user is logged
 * return: If user exist return true else return false
 **/
export const isLogged = () => {

    // return !!sessionStorage.getItem("user") && !!sessionStorage.getItem("userTk");
    return true
};


/**
 * Method that removed session data and logout user
 **/
export const removeSession = () => {
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("userTk");
    sessionStorage.clear();
};


/**
 * Method that return user data
 **/
export const getUser = () => {
    if (isLogged()) {
        return JSON.parse(sessionStorage.getItem("user"));

    } else {
        return null;
    }
};


/**
 * Method save user data
 **/
export const saveUser = (data, auth) => {
    let status;
    try {
        if ((data != null && auth != null) || (data !== undefined && auth !== undefined)) {
            sessionStorage.setItem("user", JSON.stringify(data));
            sessionStorage.setItem("userTk", JSON.stringify(auth));
            status = true;

        } else {
            status = false;
        }

    } catch (e) {
        console.log(e);
        status = false;
    }

    return status;
};


/**
 * Method that save storage data
 * @param {String} key
 * @param {Array} value
 **/
export const saveData = (key, value) => {
    if ((key != null && value != null) || (key !== undefined && value !== undefined))
        sessionStorage.setItem(key, JSON.stringify(value));
};


/**
 * Method that return storage data
 * @param {String} key
 * return: Array
 **/
export const getData = (key) => {
    if (sessionStorage.getItem(key)) {
        return JSON.parse(sessionStorage.getItem(key));

    } else {
        return null;
    }
};


/**
 * Method that remove storage data
 * @param {String} key
 **/
export const removeData = (key) => {
    sessionStorage.removeItem(key);
};



export const isSuccessfulCreate = (response) => {
    return response.status === RESULT_CREATED;
};

export const isSuccessfulRequest = (response) => {
    return response.status === RESULT_SUCCESS && response.data.result !== "transaction_failed";
};

/**
 * Method convert boolean to yes or no
 * @param {boolean} value
 * return: String
 **/
export const convertToYesNo = (value) => {
    if (value === true) {
        return YES;
    } else {
        return NO;
    }
};


/**
 * Method disable DOM component
 * @param {Object} component
 * @param {String} state
 **/
export const disableComponent = (component, state) => {
    // component.setState({ disableField: true} );
    component.setState({[state]: true });
};


/**
 * Method enable DOM component
 * @param {Object} component
 * @param {String} state
 **/
export const enableComponent = (component, state) => {
    // component.setState({ disableField: false} );
    component.setState({[state]: false });

};



/**
 * Method that return string text separated with ','.
 * @param {Array} array
 * return: String
 **/
export const getStringTextFormatByName = (array) => {

    if (array !== undefined || null) {

        try {
            let names = array.map(({ name }) => name);
            return names.join(", ", );

        } catch (e) {
            console.log(e);
            return "";
        }

    } else {
        return "";
    }
};


/**
 * Method that return string value.
 * @param {Array} array
 * @param {String} property
 * return: String
 **/
export const  nullController = (array, property) => {
    let value = '';

  try {
      if (array === null || array === undefined) {
          value = '';

      } else {
          if (array[property] === null || array[property] === undefined) {
              value = '';

          } else if ((typeof array[property]) === 'object') {
              value = 'Invalid data';

          } else {
              value = array[property];
          }
      }

  }  catch (e) {
      console.log(e);
  }

  return value;
};


/**
 * Method that verify if the value is new or not.
 * @param {Array} oldArray [Required]
 * @param {String} property [Required]
 * @param {Number} valueId [Required]
 * return: boolean
 **/
export const validateNewValue = (oldArray, property, valueId) => {
    let isValid = false;

    try {
        let index = oldArray.findIndex(val => val[property] === valueId);
        if (index !== -1)
            isValid = true;

    } catch (e) {
        console.log("Something went wrong.");
    }

    return isValid;
};




/**
 * Method group array by specific number.
 * @param {Array} array [Required]
 * @param {number} rows [Required]
 * @param {number} columns [Required]
 * return: Array
 **/
export const groupArray = (array, columns, rows) => {
    let arrayRows = array.reduce((r, e, i) =>
        (i % columns ? r[r.length - 1].push(e) : r.push([e])) && r
        , []);

    return arrayRows.reduce((r, e, i) =>
        (i % rows ? r[r.length - 1].push(e) : r.push([e])) && r
        , []);
};



export const getLanguage = () => {
    let language;

    try {
        language = window.navigator.language.slice(0, 2);
    } catch (e) {
        language = '';
    }

    return language;
};


export function hexToBase64(hexstring) {
    return btoa(hexstring.match(/\w{2}/g).map(function(a) {
        return String.fromCharCode(parseInt(a, 16));
    }).join(""));
}


export const getTimeZone = () => {
    let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (timeZone === undefined) {
        timeZone = null;
    }

    return timeZone;
};


export const getNonceProvider = () => {
    return document.querySelector(`meta[property="csp-nonce"]`).getAttribute('content');
};


/**
 * Method that verify if password is valid strong password.
 * @param {String} password [Required]
 * return: boolean
 **/
export const validatePassword = (password) => {
    /**
     * Password must contain 1 number (0-9), one uppercase letters,
     * one lowercase letters, one non-alpha numeric number
     * and a least of 8 characters with no space
     * **/
    const validPass =  /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){8,}$/;

    return password.match(validPass);
};


export const getOperativeSystem = () => {
    let os = "iPhone";

    try {
        if (navigator.appVersion.indexOf("Android") !== -1) {
            os = "Android";
        } else if (navigator.appVersion.indexOf("iPhone") !== -1) {
            os = "iOS"
        }

    } catch (e) {
        console.log("Something went wrong");
    }

    return os;
};

/**
 * Method check permission access
 * @param {String} appType
 * @param userType (at the moment this parameter is not undefined)
 * return: boolean
 **/
export const checkComponentAccess = (appType, userType) => {
    try {
        if (appType === APP_COMMERCE || appType === APP_ACTIVITY) {
            return !(userType === undefined || userType === null);

        } else {
            console.log("Invalid application");
            return false;
        }

    } catch (e) {
        console.log("Something went wrong: " + e);
    }

};


/**
 * Formatt a date with since
 * @param {String} date
 * @param props to use translation function
 * return: formatted date ej 4 minutes ago
 **/
export const timeFormatSince = (_date) => {
    let language = window.navigator.language.slice(0, 2);
    let intervalType;

    if (language === undefined || language === null) {
        language = 'en';
    }

    const date = new Date(_date);

    let seconds = Math.floor((new Date() - date) / 1000);

    let interval = seconds / 31536000;

    if (interval >= 1) {
        if (interval > 1 || interval === 0) {
            intervalType = language === 'es' ? "hace " + interval + " años" : interval + " years ago";
        } else {
            intervalType = language === 'es' ? "hace un año" : "a year ago";
        }
    } else {
        interval = Math.floor(seconds / 2592000);
        if (interval >= 1) {
            if (interval > 1 || interval === 0) {
                intervalType = language === 'es' ? "hace " + interval + " meses" : interval + " months ago";
            } else {
                intervalType = language === 'es' ? "hace un mes" : "a month ago";
            }
        } else {
            interval = Math.floor(seconds / 86400);
            if (interval >= 1) {
                if (interval > 1 || interval === 0) {
                    intervalType = language === 'es' ? "hace " + interval + " días" : interval + " days ago";
                } else {
                    intervalType = language === 'es' ? "hace un día" : "a day ago";
                }
            } else {
                interval = Math.floor(seconds / 3600);
                if (interval >= 1) {
                    if (interval > 1 || interval === 0) {
                        intervalType = language === 'es' ? "hace " + interval + " horas" : interval + " hours ago";
                    } else {
                        intervalType = language === 'es' ? "hace una hora" : "a hour ago";
                    }
                } else {
                    interval = Math.floor(seconds / 60);
                    if (interval >= 1) {
                        if (interval > 1 || interval === 0) {
                            intervalType = language === 'es' ? "hace " + interval + " minutos" : interval + " minutes ago";
                        } else {
                            intervalType = language === 'es' ? "hace un minuto" : "a minute ago";
                        }
                    } else {
                        interval = seconds;
                        if (interval > 1) {
                            intervalType = language === 'es' ? "hace " + interval + " segundos" : interval + " seconds ago";
                        } else  {
                            intervalType = language === 'es' ? "hace un momento" : "a while ago";
                        }
                    }
                }
            }
        }
    }


    return intervalType;
};