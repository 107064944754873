import React from "react";
import ReactDOM from "react-dom";

// core components
import Main from "layouts/Main.jsx";
import '../src/utils/i18n';
import "assets/css/material-dashboard-react.css?v=1.7.0";



ReactDOM.render(<Main/>, document.getElementById("root"));
