// #############################
// Parameters of configurations
// #############################

const ROWS_PER_PAGE                 = 10;
const ROWS_PER_PAGE_DETAILS         = 5;
const ROWS_PER_PAGE_OPTIONS         = [10, 25, 50];
const ROWS_PER_PAGE_OPTIONS_DETAILS = [5, 10, 25];


// ##############################
// Google Services
// #############################


// ##############################
// Endpoints for management dashboard
// #############################

/** BASE URL **/
const BASE_URL_ADMIN_API    = process.env.REACT_APP_ADMIN_API_URL;
const BASE_URL_ACCOUNT_API  = process.env.REACT_APP_ACCOUNT_API_URL;

const ORDERS                = "/list-order";
const CANCEL_ORDER          = "/order-status-cancel";
const ORDERS_SUMMARY        = "/orders-summary";
const LOGIN                 = "/auth/administrative/login";

const HEADER_API_KEY        = process.env.REACT_APP_ADMIN_API_KEY;


module.exports = {
  ROWS_PER_PAGE_OPTIONS,
  ROWS_PER_PAGE_OPTIONS_DETAILS,
  ROWS_PER_PAGE,
  ROWS_PER_PAGE_DETAILS,
  BASE_URL_ADMIN_API,
  BASE_URL_ACCOUNT_API,
  ORDERS,
  LOGIN,
  HEADER_API_KEY,
  CANCEL_ORDER,
  ORDERS_SUMMARY
};
