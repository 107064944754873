/** Create by Marlon Reyes 26/10/2020 **/

import {
    dangerColor,
    defaultFont,
    grayColor,
    primaryColor, successColor,
    whiteColor
} from "../material-dashboard-react";


const formStyle = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    },
    colorIcon:{
        color: grayColor[0]
    },
    formControl: {
        minWidth: "100%",
    },
    datePickerControl: {
        minWidth: "100%",
        marginLeft: "15px",
    },
    selectControl: {
        marginTop: 0,
    },
    selectControlMax: {
        marginTop: 20,
        marginBottom: 40,
    },
    dialogBody:{
        padding: "8px 5px",
    },
    formContainer: {
        marginBottom: 1,
    },
    scheduleContainer:{
        margin: "4px",
    },
    itemsGroupContainer:{
        marginTop: "20px",
    },
    formActions:{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 30,
    },
    buttonSpacing:{
        marginLeft: 5,
        marginRight:5,
    },
    cancelButton:{
        border: "1px solid #A2A2A2"
    },
    rightPaper:{
        float: "right",
        marginTop: 30,
    },
    center:{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 20,
    },
    centerPaper:{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    buttonContainer:{
        marginTop: 60,
    },
    subtitleContent:{
        marginBottom: 25,
    },
    selectTitle:{
        ...defaultFont,
        color: grayColor[3] + " !important",
        fontWeight: "400",
        fontSize: "11px",
        lineHeight: "1.42857",
        letterSpacing: "unset"
    },
    timerContainer:{
        marginTop: 0,
    },
    addScheduleButton: {
        marginTop: 1,
    },
    iconButtonGreen: {
        width: "20px",
        height: "20px",
        padding: "0",
        color: successColor[2]
    },
    iconButtonRed: {
        width: "20px",
        height: "20px",
        padding: "0",
        color: dangerColor[0]
    },
    iconButtonBlue: {
        width: "20px",
        height: "20px",
        padding: "0",
        color: primaryColor[0]
    },
    separator:{
        marginLeft: "20px",
        marginRight: "20px",
    },
    switchBase: {
        color: primaryColor[0],
        "&$checked": {
            color: primaryColor[0],
        },
        "&$checked + $track": {
            backgroundColor: primaryColor[0],
        },
    },
    leftPaper:{
        float: "left",
        marginTop: 10,
    },
    checked: {},
    track: {},


    btnImage: {
        position: "relative",
        border: "1px dashed gray",
        "&:hover, &$focusVisible": {
            zIndex: 1,
            "& $imageBackdrop": {
                opacity: 0.4,
            },
        },
    },
    focusVisible: {},
    imageButton: {
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: grayColor[2],
    },
    imageSrcContainer: {
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center 40%",
    },
    imageBackdrop: {
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: grayColor[9],
        opacity: 0.25,
        transition: "opacity",
    },
    imageTitle: {
        position: "relative",
        padding: "2px 4px 7px",
    },
    imageMarked: {
        height: 3,
        width: 18,
        backgroundColor: grayColor[2],
        position: "absolute",
        bottom: -2,
        left: "calc(50% - 9px)",
    },
    descriptionTitle: {
        color: grayColor[2],
        fontWeight: "400",
        lineHeight: "1.42857",

    },
    imageSelector: {
        height: 300,
        width: 500,
    },
    imageEditorContainer: {
        marginBottom: 1,
        alignContent: "center",
    },
    inputFile: {
        marginBottom: 15,
    },
    title2: {
        marginLeft: 10,
        fontSize: 17,
        fontWeight: 500,
        color: grayColor[7]
    },
    surchargeContainer:{
        '& label.Mui-focused': {
            color: primaryColor[0],
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: primaryColor[0],
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#C4C4C4',
            },
            '&:hover fieldset': {
                borderColor: '#C9C9C9',
            },
            '&.Mui-focused fieldset': {
                borderColor: primaryColor[0],
            },
        },
        width: 100,
    },
    itemNameContainer:{
        '& label.Mui-focused': {
            color: primaryColor[0],
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: primaryColor[0],
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#C4C4C4',
            },
            '&:hover fieldset': {
                borderColor: '#C9C9C9',
            },
            '&.Mui-focused fieldset': {
                borderColor: primaryColor[0],
            },
        },
    },
    itemSelect: {
      marginTop: 10
    },
    buttonFit: {
        paddingLeft: 10,
        paddingRight: 10,
        marginLeft: 5,
    },
    objectColorGrey: {
        color: grayColor[2],
        width: "17px",
        height: "17px",
        marginRight: 10,
    },
    objectColorWhite: {
        color: whiteColor,
        width: "17px",
        height: "17px",
        marginRight: 10,
    },
    draggableObject:{

    },
    buttonLink: {
        ...defaultFont,
        cursor: "pointer",
        position: "relative",
        display: "block",
        padding: "10px 15px",
        marginLeft: 5,
        marginRight:5,
        textDecoration: "none",
        transition: "all .3s",
        border: "0 !important",
        borderRadius: "3px",
        textAlign: "center",
        backgroundColor: primaryColor[0],
        color: whiteColor,
        minHeight: "auto",
        width: "80px",
        fontWeight: "400",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        fontSize: "12px",
        lineHeight: "1.42857143rem",
        "&:hover": {
            backgroundColor: primaryColor[0],
            opacity: 0.7,
            color: whiteColor
        },
        "&:visited":{
            color: whiteColor
        },
        "&:link":{
            color: whiteColor
        },
        "&:active":{
            color: whiteColor
        }
    },
    formTitle: {
        fontSize: "1.25rem",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        fontWeight: "500",
        lineHeight: 1.6,
        letterSpacing: "0.0075em"
    },
    selectDescription: {
        marginTop: 10
    }
};

export default formStyle;