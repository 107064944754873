import {BASE_URL_ADMIN_API, CANCEL_ORDER, ORDERS, ORDERS_SUMMARY} from "../variables/apiConstants";
import {getService, postService} from "./api";


export function getAllPendingOrders() {
    let url = BASE_URL_ADMIN_API + ORDERS;
    return getService(url);
}

export function cancelOrder(parameters) {
    let url = BASE_URL_ADMIN_API + CANCEL_ORDER;
    return postService(url, parameters);
}

export function getOrdersSummary(parameters) {
    let url = BASE_URL_ADMIN_API + ORDERS_SUMMARY;
    return postService(url, parameters);
}
