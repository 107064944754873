import React from "react";
import {withTranslation} from "react-i18next";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons icons
import SearchIcon from "@material-ui/icons/Search";
// core components
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import Card from "../../components/Card/Card.jsx";
import CardBody from "../../components/Card/CardBody.jsx";
import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "@material-ui/core/Input";
import tableViewStyle from "../../assets/jss/custom-components/tableViewStyle";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {withRouter} from "react-router-dom";
import TableBody from "@material-ui/core/TableBody";
import Loading from "../../components/Loading/Loading";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import {ROWS_PER_PAGE, ROWS_PER_PAGE_OPTIONS} from "../../variables/apiConstants";
import TablePagination from "@material-ui/core/TablePagination";
import {validateSession} from "../../services/api";
import {nullController, timeFormatSince} from "../../utils/helpersFunctions";
import Snackbar from "../../components/Snackbar/Snackbar";
import NotificationSuccessIcon from "@material-ui/icons/DoneAll";
import {NOTIFICATION_DURATION} from "../../variables/coreConstants";
import {getAllPendingOrders} from "../../services/ordersService";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Muted from "../../components/Typography/Muted";
import DialogContent from "@material-ui/core/DialogContent";
import OrderStatusForm from "./components/OrderStatusForm";
import orderDetails from "../../models/OrderModel";
import Status from "../../components/Status/Status";
import NotificationFailedIcon from "@material-ui/icons/Cancel";
import {lightColors} from "../../assets/jss/material-dashboard-react";


class Orders extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            isLoading: false,
            openForm: false,
            actionType: 0,
            ordersData: [],
            filteredOrders: [],
            rowsPerPage: ROWS_PER_PAGE,
            page: 0,
            notificationMessage: '',
            notification: false,
            color: 'success',
            icon: NotificationSuccessIcon,
        };
        this.closeForm = this.closeForm.bind(this);
    }

    componentDidMount() {
        this.request();
    }

    showNotification = (msj, color, icon) => {
        this.setState({
            notificationMessage: msj,
            color: color,
            icon: icon,
            notification: true,
        });

        window.setTimeout(
            function() {
                this.setState({ notification: false });
            }.bind(this),
            NOTIFICATION_DURATION
        );
    };

    hideNotification = (event, reason) => {
        if (reason === 'clickaway') { return; }
        this.setState({ notification: false });
    };

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: + event.target.value});
        this.setState({ page: 0 });
    };


    handleOpenForm = (formData) => {
        orderDetails.cleanOrderDetails();
        orderDetails.setOrderDetail(formData);
        this.setState({ openForm: true });
    };

    handleCloseForm = () => {
        this.setState({ openForm: false });
    };

    closeForm(state, value, status) {
        this.setState({[state]: value});
        if (status !== null) {
            this.request();
        }
    }

    request = () => {
        const { t } = this.props;
        this.setState({ isLoading: true} );

        getAllPendingOrders()
            .then((res) => {
                if(res.data.length > 0) {
                    this.setState({ordersData: res.data});
                    this.setState({filteredOrders: res.data});
                }

                this.setState({ isLoading: false });
            })
            .catch((error) => {
                this.setState({ isLoading: false} );
                if (error.response) {
                    this.showNotification(error.response.message, "danger", NotificationFailedIcon, null);
                    validateSession(error.response, this.props.history);
                } else {
                    this.showNotification(t('SOMETHING_WENT_WRONG'), "danger", NotificationFailedIcon, null);
                }
            });
    };

    // Method to search data
    searchOrder = (e) => {
        let newData = this.state.ordersData;
        try {
            newData = newData.filter(function(item){
                return item.storeName.toLowerCase().search(e.target.value.toLowerCase()) !== -1
                || item.orderIdentifier.toLowerCase().search(e.target.value.toLowerCase()) !== -1
                || item.customerName.toLowerCase().search(e.target.value.toLowerCase()) !== -1;
            });
            this.setState({filteredOrders: newData});

        } catch (e) {
            console.log("Something went wrong.");
        }
    };


    render() {
        const { classes, t } = this.props;
        const { page, rowsPerPage } = this.state;
        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                        <GridItem xs={6} sm={6} md={6}>
                            <Input
                                onChange={this.searchOrder}
                                className={classes.search}
                                startAdornment={
                                    <InputAdornment position="end">
                                        <SearchIcon className={classes.objectColor}/>
                                    </InputAdornment>
                                }
                                placeholder={t('SEARCH_ORDER_TEXT')}
                            />
                        </GridItem>
                    </GridContainer>
                    {this.state.isLoading ?
                        <Loading/>
                        :
                        <Card>
                            <CardBody>
                                <div className={classes.tableResponsive}>
                                    <Table className={classes.table}>
                                        <TableHead className={classes.thColor}>
                                            <TableRow style={{backgroundColor: lightColors[0]}}>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    {t('STORE_NAME_TEXT')}
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    {t('TH_STORE_PHONE_TEXT')}
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    {t('TH_COMPANY_PHONE_TEXT')}
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    {t('TH_ORDER_NUMBER')}
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    {t('TH_ORDER_DELIVERY_TYPE')}
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    {t('TH_ORDER_CUSTOMER')}
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    {t('TH_CUSTOMER_PHONE')}
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    {t('TH_CREATED_DATE')}
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCell + " " + classes.tableHeadCell}>
                                                    {t('TH_ACTIONS')}
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.filteredOrders.slice(
                                                page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((val, key) => {
                                                return (
                                                    <TableRow key={key} hover={true}>
                                                        <TableCell
                                                            className={classes.tableCell}>
                                                            {nullController(val, 'storeName')}
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            {nullController(val, 'storePhone')}
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            {nullController(val, 'companyPhone')}
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            {nullController(val, 'orderIdentifier')}
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            {t(nullController(val, 'deliveryType'))}
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            {nullController(val, 'customerName')}
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            {
                                                                nullController(val.customerPhone, 'valueAttribute') !== ''
                                                                && nullController(val.customerPhone, 'detailValue') !== ''
                                                                ? ("+" + nullController(val.customerPhone, 'valueAttribute')
                                                                    + " " + nullController(val.customerPhone, 'detailValue'))
                                                                : null
                                                            }
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            <Status
                                                                className={classes.status}
                                                                color={"default"}
                                                                size="lg"
                                                                label={timeFormatSince(nullController(val, "createdDate"))}
                                                            />
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}>
                                                            {
                                                                <IconButton
                                                                    onClick={() => this.handleOpenForm(val)}
                                                                    className={classes.tableActionButton}
                                                                >
                                                                    <EditIcon className={classes.tableActionButtonIcon}/>
                                                                </IconButton>
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </div>
                                <TablePagination
                                    rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                                    component="div"
                                    count={this.state.filteredOrders.length}
                                    rowsPerPage={this.state.rowsPerPage}
                                    page={this.state.page}
                                    backIconButtonProps={{
                                        'aria-label': 'previous page',
                                    }}
                                    nextIconButtonProps={{
                                        'aria-label': 'next page',
                                    }}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />
                            </CardBody>
                        </Card>
                    }
                </GridItem>
                <Dialog
                    maxWidth={"sm"}
                    fullWidth={true}
                    open={this.state.openForm}
                    onClose={this.handleCloseForm}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">
                        {t('FORM_ORDER_STATUS_TITLE')}
                        <Muted>
                            {t('FORM_ORDER_STATUS_DESCRIPTION')}
                        </Muted>
                    </DialogTitle>
                    <DialogContent>
                        <OrderStatusForm closeForm={this.closeForm}/>
                    </DialogContent>
                </Dialog>
                <Snackbar
                    place="tc"
                    color={this.state.color}
                    icon={this.state.icon}
                    message={this.state.notificationMessage}
                    open={this.state.notification}
                    closeNotification={this.hideNotification}
                    close
                />
            </GridContainer>
        );
    }

}

Orders.propTypes = {
    classes: PropTypes.object
};

export default withRouter(withStyles(tableViewStyle)(withTranslation()(Orders)));
