import React from 'react';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material helpers
import { withStyles } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';

import {
    primaryColor,
    successColor,
    dangerColor,
    infoColor,
    warningColor
} from "../../assets/jss/material-dashboard-react";

const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        fontWeight: 'bold',
    },
    sm: {
        height: theme.spacing(1),
        width: theme.spacing(1)
    },
    md: {
        height: theme.spacing(1) * 2,
        width: theme.spacing(1) * 2
    },
    lg: {
        height: theme.spacing(1) * 3,
        width: theme.spacing(1) * 3
    },
    primary: {
        backgroundColor: primaryColor[1],
    },
    info: {
        backgroundColor: infoColor[1]
    },
    warning: {
        backgroundColor: warningColor[1]
    },
    danger: {
        backgroundColor: dangerColor[1]
    },
    success: {
        backgroundColor: successColor[1]
    },
    default: {
        backgroundColor: '#F3F9FF'
    },
});

const Status = props => {
    const { classes, className, size, color, label, ...rest } = props;

    const rootClassName = classNames(
        {
            [classes.root]: true,
            [classes[size]]: size,
            [classes[color]]: color,
        },
        className
    );

    return (
        <Chip
            {...rest}
            className={rootClassName}
            label={label ? label : 'Undefined'}
            //
        />
    );
};

Status.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    classes: PropTypes.object.isRequired,
    color: PropTypes.oneOf([
        'primary',
        'info',
        'success',
        'warning',
        'danger',
        'default'
    ]),
    size: PropTypes.oneOf(['sm', 'md', 'lg'])
};

Status.defaultProps = {
    size: 'md',
    color: 'default',
    label: 'undefined'
};

export default withStyles(styles)(Status);
