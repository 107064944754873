import ic_Receipt from "@material-ui/icons/Receipt";
import ic_Analytics from "@material-ui/icons/BarChart";
// components/views for Admin layout
import Orders from "../src/views/Orders/Orders";
import OrdersSummary from "./views/OrderSummary/OrdersSummary";


const dashboardRoutes = [
    {
        path: "/pending-orders",
        name: "PENDING_ORDERS_TEXT",
        icon: ic_Receipt,
        component: Orders,
        appType: "Default",
        layout: "/admin"
    },
    {
        path: "/orders-summary",
        name: "ORDERS_SUMMARY_TEXT",
        icon: ic_Analytics,
        component: OrdersSummary,
        appType: "Default",
        layout: "/admin"
    },
];

export default dashboardRoutes;
