const statusColors = {
    0: 'warning',
    1: 'success',
    2: 'success',
    3: 'warning',
    4: 'success',
    5: 'danger',
    6: 'success',
    7: 'danger',
    8: 'warning',
    9: 'danger',
    10: 'success',
    11: 'success',
    13: 'danger',
    16: 'warning',
};
const statusTextColors = {
    0: 'statusWarning',
    1: 'statusSuccess',
    2: 'statusSuccess',
    3: 'statusWarning',
    4: 'statusSuccess',
    5: 'statusDanger',
    6: 'statusSuccess',
    7: 'statusDanger',
    8: 'statusWarning',
    9: 'statusDanger',
    10: 'statusSuccess',
    11: 'statusSuccess',
    13: 'statusDanger',
    16: 'statusWarning',
};

const statusText = {
    0: 'STATUS_INACTIVE',
    1: 'STATUS_ACTIVE',
    2: 'STATUS_DELIVERED',
    3: 'STATUS_PENDING',
    4: 'STATUS_PAID',
    5: 'STATUS_NOT_PAID',
    6: 'STATUS_ASSIGNED',
    7: 'STATUS_CANCELLED',
    8: 'STATUS_UNASSIGNED',
    9: 'STATUS_REVOKED',
    10: 'STATUS_SUCCESS',
    11: 'STATUS_EXTERNAL_FAILED',
    13: 'STATUS_FAILED',
    14: 'STATUS_IN_PROGRESS',
    15: 'STATUS_COMPLETED',
    16: 'STATUS_NOT_COMPLETED',
};

const formTitles = {
    0: 'FORM_NEW_TEXT',
    1: 'FORM_EDIT_TEXT',
};


module.exports = {
    statusColors,
    statusTextColors,
    statusText,
    formTitles,
};