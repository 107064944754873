import {makeStyles} from "@material-ui/core";
import {
    dangerColor,
    defaultFont,
    grayColor,
    primaryColor,
    primaryVerticalGradient, successColor, warningColor,
    whiteColor
} from "../material-dashboard-react";
import colors from "../../../utils/colors";
import {green} from "@material-ui/core/colors";

const signInStyle = makeStyles(theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: "20%"
    },
    paperLogin: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',

    },
    avatar: {
        margin: theme.spacing(0),
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        background: primaryColor[0],
        '&:hover': {
            backgroundColor: primaryColor[0],
        },
    },
    image:{
        width: '300px',
        height: '250px',
        marginTop: 30,
    },
    logoContainer:{
        width: '150px',
        height: '50px',
        marginBottom: 10
    },
    objectsColor:{
        colors: colors.primary,
    },
    inputStyle:{
        borderColor: colors.primary,

    },
    container: {
        overflowX: 'hidden',
        height: '100%',
        backgroundSize: 'center',
        backgroundRepeat: 'repeat',
        backgroundPosition: 'center',
    },
    welcomeMessage: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white'
    },
    welcomeWrapper: {
        opacity: 1,
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        //backgroundColor: primaryColor[0],
        background: primaryVerticalGradient.background,
        backgroundSize: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    },

    close: {
        padding: theme.spacing(0.5),
    },
    progress: {
        margin: theme.spacing(2),
        marginTop: theme.spacing(4),
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    button: {
        margin: theme.spacing(2),
    },
    placeholder: {
        height: 40,
    },
    success: {
        backgroundColor: green[600],
    },
    switchContainer:{
        fontSize: '12px',
    },
    privacyPolicyLabel:{
        fontSize: '12px',
    },
    privacyPolicyLink:{
        fontSize: '12px',
        color: primaryColor[0],
    },
    checkBoxColor:{
        color: primaryColor[0],
        '&$checked': {
            color: primaryColor[0],
        },
    },
    checked:{

    },
    loadingButton:{
        color: whiteColor,
        marginLeft: '10px',
    },
    loadingPaper:{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    stepper: {
        cursor: 'pointer',
        padding: theme.spacing(0, 0, 4),
    },
    stepColor:{
        color: primaryColor[0],
    },
    selectControl: {
        marginTop: 500,
    },
    selectTitle:{
        ...defaultFont,
        color: grayColor[3] +  '!important',
        fontWeight: '400',
        fontSize: '11px',
        lineHeight: '1.42857',
        letterSpacing: 'unset'
    },
    formControl: {
        marginTop: 9,
    },
    textFieldPrimary: {
        '& label.Mui-focused': {
            color: primaryColor[0],
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: primaryColor[0],
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#C4C4C4',
            },
            '&:hover fieldset': {
                borderColor: '#000000',
            },
            '&.Mui-focused fieldset': {
                borderColor: primaryColor[0],
            },
        },
    },
    textFieldError: {
        '& label.Mui-focused': {
            color: dangerColor[0],
        },
        '&:hover fieldset': {
            color: dangerColor[0],
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: dangerColor[0],
        },
        '&.Mui-focused fieldset': {
            borderColor: dangerColor[0],
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#C4C4C4',
            },
            '&:hover fieldset': {
                borderColor: dangerColor[0],
            },
            '&.Mui-focused fieldset': {
                borderColor: dangerColor[0],
            },
        },
    },
    textFieldWarning: {
        '& label.Mui-focused': {
            color: warningColor[0],
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: warningColor[0],
        },
        '&.Mui-focused fieldset': {
            borderColor: warningColor[0],
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#C4C4C4',
            },
            '&:hover fieldset': {
                borderColor: warningColor[0],
            },
            '&.Mui-focused fieldset': {
                borderColor: warningColor[0],
            },
        },
    },
    textFieldSuccess: {
        '& label.Mui-focused': {
            color: successColor[2],
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: successColor[2],
        },
        '&.Mui-focused fieldset': {
            borderColor: successColor[2],
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#C4C4C4',
            },
            '&:hover fieldset': {
                borderColor: successColor[2],
            },
            '&.Mui-focused fieldset': {
                borderColor: successColor[2],
            },
        },
    },
}));

export default signInStyle;