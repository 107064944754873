import React from 'react';
import { Switch, Route, Router, Redirect } from 'react-router-dom';
import history from '../utils/history';
import ErrorController from "../components/ErrorBoundary/ErrorController";
import PrivateRoute from "../components/PrivateRoute/PrivateRoute";
import Admin from "./Admin";
import SessionExpired from "../views/ErrorPage/SessionExpired";
import SignIn from "../views/SignIn/SignIn";


class Main extends React.Component {

    render() {
        return (
                <Router history={history} >
                    <ErrorController>
                        <Switch>
                            <PrivateRoute path="/admin" component={Admin} />
                            <Route exact={true} path="/sign-in" component={SignIn}/>
                            <Route exact={true} path="/session-expired" component={SessionExpired}/>
                            <Redirect from="/" to="/sign-in" />
                        </Switch>
                    </ErrorController>
                </Router>
        );
    }
}

export default Main;